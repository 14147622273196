import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getPathnameWithoutLocale(locale: string, pathname: string): string {
  const segments = pathname.split("/");
  if (segments[1] !== locale) throw new Error(`Path not contains locale ${locale}`);
  return segments.toSpliced(1, 1).join("/");
}
