export type LocaleKey = "de" | "en" | "es" | "fr" | "it" | "ro" | "ru";

export const availableLocaleKeys: LocaleKey[] = ["de", "en", "es", "fr", "it", "ro", "ru"];

export const availableLocales = {
  de: async () => cleanLocale(await import("./de")),
  en: async () => cleanLocale(await import("./en")),
  es: async () => cleanLocale(await import("./es")),
  fr: async () => cleanLocale(await import("./fr")),
  it: async () => cleanLocale(await import("./it")),
  ro: async () => cleanLocale(await import("./ro")),
  ru: async () => cleanLocale(await import("./ru")),
};

/**
 * Remove `undefined` values from locale translations file
 *
 * This operation is necessary for the following reasons:
 * - The library `next-international/client` not support `undefined` values on locale translations
 * - Not setting translations with the `undefined` value would cause errors when accessing translation
 * */
const cleanLocale = <T extends Record<string, unknown>>(translations: T): T => {
  return Object.fromEntries(
    Object.entries(translations)
      .filter(([, value]) => {
        return value !== undefined;
      })
      .map(([key, value]) => {
        if (Array.isArray(value)) return [key, value];
        if (typeof value === "string") return [key, value];
        return [key, cleanLocale(value as Record<string, unknown>)];
      }),
  );
};
