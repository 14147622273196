export default {
  AccessLogs: "Access logs",
  AccessN: "Access n.",
  Account: "Account",
  AccountingData: "Accounting data",
  ActionCompleted: "Action completed",
  Activate: "Activate",
  ActivationDate: "Activation date",
  ActiveLicensesInfos: "Active licenses infos",
  Add: "Add",
  AddAnyway: "Add anyway",
  AddArticle: "Add an item",
  AddAttendee: "Aggiungi nominativo",
  AddBatch: "Add series",
  AddDeliveryCosts: "Add €60 for shipping costs",
  AddOrRemoveOtherArticles: "Add / remove other items",
  Address: "Address",
  Agent: "Agent",
  AgentCard: "Agent card",
  AgentsCommissions: "Agents commissions",
  AlreadyAttendeesRegistered: "Ci sono già {attendeesCount} nominativi registrati",
  Amount: "Amount",
  AnagraphicsData: "Personal data",
  Api: "Api",
  Apply: "Apply",
  ApplyMarkup: "Applica ricarico",
  TecReport: "Tec.Repor",
  AreYouSureYouWantToProceed: "Are you sure you want to proceed?",
  Article: "Item",
  ArticleColor: "Item color",
  ArticleQty: "Item Qty",
  ArticleSize: "Item size",
  ArticleType: "Type",
  Articles: "Items",
  Artifacts: "Artifacts",
  AttendeesForSubscription: "Nominativi per l'iscrizione del ",
  Attention:
    "<b>Attenzione</b>: questa iscrizione è stata confermata, quindi nessuna ulteriore modifica verrà salvata.",
  Audits: "Audits",
  AveragePrc: "Avg price",
  SoldPieces: "Sold pieces",
  Back: "Back",
  BackOfficePolicy: "Back-office service management (treatment no.7 art 5 Privacy policy)",
  BackToQuotationList: "Torna alla lista preventivi",
  BackToTop: "Back to top",
  Balance: "Balance",
  Batch: "Series",
  BatchDesc: "Series desc",
  BatchLot: "Batch",
  BillNum: "Invoice N°",
  BilledItems: "Billed items",
  BillingCalculationNotes:
    "*** Total turnover with tinting , accessories , made ​​by automatic order , etc ..\nExcludes debit notes and manuals credit",
  BillingShort: "Revenue",
  Billings: "Revenue",
  BookingHasBeenRegistered: "La tua iscrizione è stata inserita.",
  BookingIsConfirmedSoNoChangesWillBeSaved:
    "<b>Attenzione</b>: questa iscrizione è stata confermata, quindi nessuna ulteriore modifica verrà salvata.",
  BookingRegistered: "Iscrizione completata",
  Brand: "Brand",
  Brands: "Brand",
  Budget: "Budget",
  BusinessName: "Business name",
  BusinessNameShort: "Name",
  Cancel: "Cancel",
  CancelOrder: "Delete order",
  CargoManifest: "Cargo manifest",
  Case: "Case",
  CaseDetails: "Case details",
  CaseOrigin: "Case origin",
  CashDiscount: "cash discount",
  Catalog: "Listino",
  CatalogUnlockCode: "Catalog unlock code",
  Catalogs: "Catalogs",
  Category: "Category",
  Changelog: "Changelog",
  ChooseFile: "Choose the file",
  City: "City",
  Client: "Customer",
  ClientCodeShort: "Customer code",
  ClientPickUpOnSite: "Withdrawal in factory",
  CoSpeDi: "Co.spe.di.",
  Code: "Code",
  CodeOrArticleDescription: "Code/Description item",
  Coding: "Code",
  Color: "Colour",
  ColoringCostPerUnit: "Coloring cost per unit",
  Commission: "Commission",
  CommissionAmount: "Commission amount",
  CommissionSummary: "Summary commissions",
  CommissionsFileName: "commissions",
  Companies: "Companies",
  CompanionIsPresent: "È presente un accompagnatore",
  Company: "Company",
  CompanyCreateError: "There was an error during the company creation.",
  CompanyCreateSuccess: "Company created!",
  Complaints: "Complaints",
  Confirm: "Confirm",
  ConfirmDeleteTitle: "Confirm delete operation",
  ConfirmOrder: "Confirm order",
  ConfirmRemoveItem: "Sure you want to remove the item {productCode}?",
  ConfirmedOrder: "Order confirmed",
  ContractDate: "Contract date",
  ContractNumber: "Contract number",
  ContractType: "Contract type",
  MaintenanceAgreement: "Maintenance agreement",
  Copied: "Copied",
  CouldNotAuthenticate: "Could not authenticate",
  Country: "Country",
  Courses: "Courses",
  Create: "Create",
  CreateNewBrand: "Create a new Brand",
  CreateOrEditBrand: "Create or edit a Brand",
  CreatedAt: "Created on",
  Creator: "Creator",
  CubicMetersPerPlatform: "m³ per pedana",
  CurrentOrder: "Current order",
  CustomEmailEng: "Custom email eng",
  CustomEmailIta: "Custom email ita",
  Customer: "Customer",
  CustomerAccountingFileName: "customer_accounting",
  CustomerCard: "Customer card",
  CustomerCode: "Customer code",
  CustomerDiscountsFileName: "customer_discounts",
  Customers: "Customers",
  CustomersBillings: "Sales by customer",
  CustomersCards: "Customers card",
  CustomersRanking: "Customer Ranking",
  Database: "Database",
  DatabaseUpdates: "Database updates",
  Date: "Date",
  DaysFromToday: "Giorni da oggi",
  Ddt: "Transport document",
  Deactivate: "Deactivate",
  Delete: "Delete",
  DeleteCartDialogDescription: "Do you confirm you want to cancel this order ?",
  DeleteStore: "Delete",
  DeleteStoreDialogDescription: "Are you sure to delete the store",
  DeleteUserDialogDescription: "Are you sure to delete the user",
  DeliveredToBusinessName: "Delivered business name",
  DeliveryAddress: "Delivery address",
  DeliveryStatus: "Delivery status",
  DeltaBudget: "Delta fatt",
  DeltaQta: "Delta qta",
  Department: "Commercial department",
  Description: "Description",
  DescriptionOrSeries: "Descrizione o serie",
  Destination: "Destination",
  Details: "Details",
  Diff: "Diff",
  Discount: "Discount",
  Discount1: "Sconto 1",
  Discount2: "Sconto 2",
  DiscountOnPurchasePrice: "Sconto",
  Discounted: "Scontato",
  DiscountedMaterialCost: "Scontato",
  DiscountedRawMaterial: "Scontato",
  Discounts: "Discounts",
  DiscountsCard: "Discounts card",
  Documents: "Documents",
  Download: "Download",
  DownloadApp: "Download app",
  DownloadOrderPdfDetail: "scarica dettaglio ordine (.pdf)",
  DownloadOrderPdfSimplify: "scarica versione semplificata ordine (.pdf)",
  Downloads: "Downloads",
  DueToExpire: "Due to expire",
  Duration: "Durata",
  Edit: "Edit",
  EditArticle: "Edit item",
  EditDatabase: "Edit Database",
  EditIntexSoftware: "Edit Intex software",
  EditSoftware: "Edit Software",
  EditStore: "Edit store",
  EditSubscription: "Modifica iscrizione",
  Email: "Email",
  EndNotes: "End verification notes",
  EndServiceDate: "End service date",
  Execute: "Execute",
  ExecutedBy: "Created by",
  Export: "Export",
  ExportExcel: "Export to excel",
  ExternalId: "External ID",
  FastDelivery: "Withdrawal in factory",
  Fax: "Fax",
  FeatureId: "Feature id",
  FeatureTypes: "Feature types",
  FileSize: "File size",
  FilterByBrands: "Filter by brand",
  FilterByGroups: "Filter by groups",
  FilterBySeries: "Filter by series",
  Filters: "Filters",
  FinalSellingPrice: "Prezzo di vendita finale",
  FindedRepeatedItemsInTheCart: "There are repeated items in this cart",
  From: "From",
  FromListing: "Da listino",
  FromToInResults: "from {from} to {to} of {total} results",
  FullName: "Nome completo",
  GeneralTotals: "Totali generali",
  GenerateNewBrand: "Feature id",
  GenerateNewLicenseBatch: "Generate new license batch",
  GenerateOrUpdateLicenseBatch: "Generate or update the license batch",
  GenericFailure: "Error! Something went wrong!",
  GrossAmount: "Gross amount",
  GrossMaterialCost: "Totale da Listino",
  GrossWeight: "Weight ",
  Group: "Gruppo",
  GroupByMonth: "Group by month",
  GroupDesc: "Group desc",
  Home: "Home",
  ID: "ID",
  IfBookLessThanAttendeesRemoveSome:
    "Se si vuole prenotare meno di {attendeesCount} posti, eliminare qualche nominativo",
  InfoOrderLowPriceDescription: "Importo troppo basso",
  InsertAttendeesEditOrRemoveAlreadyInserted:
    "Inserisci nominativi mancanti, modifica o rimuovi nominativi già inseriti",
  IntexSoftware: "Intex Software",
  InvalidCredentials: "Invalid credentials",
  InvoicedComissionShort: "Tot. Commissions",
  InvoicedCommissionShort: "Tot. Commissions",
  IsNotPossibleConfirmBookingBecauseAttendeesAndReservedSeatsNotMatch:
    "Non è possibile confermare l'iscrizione perchè il numero di posti prenotati e il numero di nominativi forniti non coincidono.",
  ItemAndMachine: "Items and Machines",
  ItemCode: "Item code",
  Items: "Items",
  KgOrLtLabel: "kg o L necessari",
  LandlinePhone: "Telefono fisso",
  Language: "Language",
  LastAccess: "Last access",
  LastObject: "Last Object",
  LastUpdateSage: "Last update SAGE",
  License: "License",
  LicenseBatches: "License batches",
  LicenseCount: "License count",
  Licenses: "Licenses",
  Load: "Load",
  LockCode: "Lock code",
  Login: "Login",
  Logo: "Logo",
  Logout: "Logout",
  Machine: "Machine",
  MachineDescription: "Machine description",
  ManageAttendees: "Gestisci nominativi",
  Manager: "Manager",
  MarketingPolicy: "Personalized marketing (treatment no.9 art 5 privacy policy)",
  Markup: "Ricarico",
  MiscellaneousExpenses: "Miscellaneous expenses",
  MobilePhone: "Cellulare",
  MonthFrom: "Month from",
  MonthTo: "Month to",
  MqForProjectLabel: "metri quadri progetto",
  MqToBeBuilt: "Mq da realizzare",
  MqToBeMade: "Mq to be Made",
  MySubscriptions: "Le tue iscrizioni",
  Name: "Name",
  Nation: "Nation",
  NetAmount: "Net amount",
  NetPrice: "Net price",
  NetTotal: "Net amount",
  NetWeight: "Net weight",
  New: "New",
  NewDatabase: "New database",
  NewIntexSoftware: "New Intex Software",
  NewOrder: "New order",
  NewSubscription: "Nuova iscrizione",
  No: "No",
  NoArticlesFound: "No items found",
  NoDeliveryCosts: "Nessun costo di spedizione",
  NoFileSelected: "No file selected",
  NoOvernightStay: "Nessun pernottamento",
  NoResults: "No results",
  Notes: "Notes",
  Nothing: "nothing",
  NowYouCan: "Ora puoi:",
  Number: "Number",
  Object: "Oggetto",
  OfflineLicensesInfos: "Offline licenses infos",
  OnceConfirmedTheSubscriptionCanNoLongerModified:
    "Una volta confermata, un'iscrizione non può più essere modificata",
  OneNightStay: "1 pernottamento",
  OpeningDate: "Opening date",
  OperatorShort: "Oper.",
  Order: "Order",
  OrderArchive: "Order history",
  OrderDate: "Order date",
  OrderDateShort: "Order date ",
  OrderItemCode: "Code",
  OrderItemDescription: "Description",
  OrderItemQuantity: "Qty",
  OrderNoNotes: "None",
  OrderNum: "Orders n°",
  OrderNumber: "Nr.",
  OrderNumberShort: "Order #",
  OrderPaymentDescription: "Payment terms",
  OrderQuantityGrossAmount: "Price",
  OrderStatus: "Order status",
  OrderTypeShort: "ord. type",
  OrderYearShort: "ord. year",
  Ordered: "Amount",
  OrderedAmount: "Ordered amount",
  OrderedCubicMeters: "m³ Ordinati",
  OrderedQtyAndOrderDelivered: "Ordered Qty/Delivered Qty",
  OrderedValue: "amount",
  Orders: "Orders",
  OrdersArchive: "Order history",
  OrdersFileName: "orders",
  OrdersOpen: "Open orders",
  OrdersPortfolio: "Order backlog",
  PackPriceOnList: "€/l;Kg da listino",
  PackagesToBuy: "Confezioni Arrotondate",
  Palletized: "Pallettized",
  Partecipants: "Partecipanti",
  Password: "Password",
  Payment: "Payment",
  PercentCommissionShort: "Commission %",
  Phone: "Phone",
  PieceSize: "Pezzatura",
  PlayStore: "Playstore",
  PostalCode: "Zip code",
  Price: "Price",
  PriceListCodeShort: "Price list code",
  PriceLists: "Price lists",
  PricePerSquareMeter: "€/m²",
  PrintOrder: "Print order",
  PrintOrderNumber: "Order number ",
  PrintableOrderVersion: "apri versione stampatbile",
  Priority: "Priority",
  PrivacyPolicy:
    "I declare that I have carefully read the contents of the Privacy Policy (Required)",
  ProceedWithSubscription: "Procedi con l'iscrizione",
  ProductAddedToCart: "Item added to cart",
  ProductAlreadyAdded: "Articolo già presente nel carrello",
  ProductCode: "codice prodotto",
  ProductCodeAndDescription: "Product code and description",
  ProductUpdated: "Updated item",
  ProductsAddedToCart: "Items added to cart",
  ProjectNameLabel: "Nome del progetto",
  Province: "Province",
  Qty: "Qty",
  Quantity: "Quantità",
  QuantityLt: "Quantity (lt)",
  QuantityPerPlatform: "Pacchi per pedana",
  Quotation: "Quotation",
  QuotationContinuous: "Continuous quotation",
  QuotationDeleted: "Preventivo eliminato",
  QuotationGroupTitle:
    "The results provided by the estimator have an indicative value, as they are obtained on the basis of the theoretical average of products",
  QuotationMth: "Quotation Marcotherm",
  QuotationMthCalculator: "Quotation",
  QuotationMthCalculatorAlert:
    "Ricordiamo che l’ordine viene evaso per un valore netto minimo fatturabile pari a € 600+IVA per il continente e € 1.200+IVA per le isole. Per i carichi inferiori ai 50 metri cubi verrà applicato un contributo trasporto di € 60 e franco destino per quelli superiori.",
  QuotationMthEstimatesAlert:
    "Il prezzo riportato dei prodotti in polvere è quello relativo alla vendita a bancali interi. Non vengono conteggiati i costi del trasporto.<br>I risultati forniti dal preventivatore hanno un valore indicativo, in quanto ottenuti sulla base della resa media teorica dei prodotti.",
  QuotationSaved: "Preventivo salvato correttamente",
  RawMaterialCost: "Costo Materiale",
  ReferentName: "Referent Name",
  ReleaseDate: "Release Date",
  ReleaseNotes: "Release Notes",
  Remove: "Remove",
  RemoveAttendee: "Rimuovi nominativo",
  ReportCategory: "Report category",
  ReportType: "Report type",
  RequiredMessage: "Required",
  RequiredPackages: "Pacchi necessari",
  RequiredPickupFromHotelToPaintshop: "Richiesto pick-up (da hotel a Colorificio San Marco)",
  RequiredProducts: "Products required",
  RequiredSquareMeters: "m² Richiesti",
  ReservedSeats: "Posti prenotati",
  ResultsPerPage: "Result per page",
  ReturnToMainPage: "Back to home",
  ReturnsToCourseDetails: "Tornare al dettaglio del corso",
  RowId: "Row id",
  RowNumber: "n° row",
  SageCode: "SAGE code",
  Save: "Save",
  SeachUserStore: "Search user, store...",
  Search: "Search",
  SearchByCodeOrDescriptionInCurrentCart: "Search cart by product code or description",
  SearchCompany: "Search company",
  SearchProductPlaceholder: "Cerca un prodotto",
  SelectACustomer: "Select a customer",
  SelectAnOption: "Select an option",
  SendActivationMail: "Send activation mail",
  Serial: "Serial",
  Series: "Series",
  SettingsForUser: "Settings for user",
  ShippedQty: "Shipped qty",
  ShippedValue: "amount",
  Show: "Show",
  SignIn: "Sign in",
  SignInWithSanMarco: "Sign in with San Marco",
  Size: "Size",
  Software: "Software",
  SoftwareName: "Software name",
  SoftwareUpdates: "Software updates",
  SquareMetersPerPackage: "m² per pacco",
  SquareMetersPerPlatform: "m² per pedana",
  StartServiceDate: "Start service date",
  StartToInsertAttendeesForTheCourse:
    "Iniziare ad inserire i nominativi delle persone che parteciperranno al corso",
  Statistics: "Statistics",
  StatisticsDisclaimer:
    "The data in this section does not take into account returns, credit notes, and other modifications to the invoice data and may therefore differ from the official data provided by San Marco Group.",
  StatisticsOrderFileName: "statistics_orders",
  Status: "Status",
  Store: "Store",
  StoreGroups: "Store groups",
  Stores: "Stores",
  Subgroup: "Subgroup",
  SubscriptionConfirmed: "L'iscrizione è stata confermata",
  SubscriptionConfirmedSuccessfully: "Iscrizione confermata con successo",
  SubscriptionId: "Id sottoscrizione",
  SubscriptionOf: "Iscrizione del ",
  SubscriptionRemoved: "Iscrizione rimossa",
  SubscriptionUpdatedSuccessfully: "Iscrizione aggiornata con successo",
  Summary: "Summary",
  Surname: "Surname",
  TaxableAmount: "Taxable amount",
  TechnicalReportFileName: "Technical report file name",
  TechnicalReportId: "Technical report ID",
  TechnicalReports: "Technical reports",
  TenDiscountForPrepayment: "Pagamento anticipato",
  TheSumOfTheSubscriptionFieldsCannotBeGreaterThan:
    "La somma dei campi '1 notte di soggiorno', '2 notti di soggiorno', 'Nessuna notte di soggiorno' non può essere maggiore di {max}",
  TicketNumber: "Ticket number",
  TicketType: "Type",
  Tintomer: "Tintometer",
  Tintometer: "Tintometer",
  Tintometers: "Tintometers",
  To: "To",
  Total: "Total",
  TotalAmount: "Total amount",
  TotalCommissionShort: "Commission amount",
  TotalCubicMetersLabel: "m³",
  TotalPrice: "Totale",
  TotalTaxableCommissionShort: "Taxable amount",
  Totals: "TOTALI",
  Tracking: "Tracking",
  TrackingDetail: "Tracking detail",
  TwoNightStay: "2 pernottamenti",
  Type: "Tipo",
  UnlockCode: "Unlock code",
  UpdateAttendee: "Aggiorna nominativo",
  UpdateSubscription: "Aggiorna l'iscrizione",
  Updates: "Updates",
  UploadedAt: "Uploaded at",
  UserId: "ID",
  UserInfo: "User info",
  Username: "Username",
  Users: "Users",
  ValidityUpTo: "valid until ",
  Vat: "Vat",
  VerificationEndNotes: "Closure verification notes",
  Version: "Version",
  VisibleToAnyoneThatBelongsToOneOfTheSelectedBrands:
    "Visible to anyone that belongs to one of the selected brands:",
  VisibleToAnyoneThatBelongsToOneOfTheSelectedStoreGroups:
    "Visible to anyone that belongs to one of the selected store groups:",
  VisibleToPublishers: "Visible to Publishers",
  VisibleToStores: "Visible to stores",
  WarningOperationIsNotReversible: "Attenzione: operazione non reversibile",
  WebEmail: "Web Email",
  Website: "Website",
  WeightKg: "Weight (Kg)",
  Year: "Year",
  Yes: "Yes",
  YouCannotBookMoreSeats: "Non puoi prenotare più di {max} posto",
  YouMustBookLeastOneSeat: "Devi prenotare almeno {min} posto",
  ZipArchive: "Zip Archive",
  ZipCode: "ZIP code",
  amountForExpiredDues: "amount for expired dues",
  amountForUnpaidDues: "amount for unpaid dues",
  billing: "revenue ***",
  closingDay: "closing day",
  coSpeDiGen: "co.spe.di gen",
  creditLimit: "credit limit",
  discountGen: "gen discount",
  expiredDues: "n° expired dues",
  ios: "iOS",
  isNecessaryEnterParticipantsToCompleteBooking:
    "è necessario inserire i dati dei partecipanti per completare la prenotazione",
  locales: {
    de: "Deutsch",
    en: "English",
    es: "Español",
    fr: "Français",
    it: "Italiano",
    ro: "Român",
    ru: "Pусский",
  },
  of: "On",
  ordersToCarryOut: "orders to carry out",
  pricesArePerPerson: "* i prezzi si intendono a persona",
  thisFieldIsRequired: "{name}: questo campo è obbligatorio",
  unpaidDues: "n° unpaid dues",
  validationErrors: {
    InvalidEmail: "Invalid email",
    RequiredMessage: "Required",
    IsMandatoryEnterContactInfoChoiceBetweenEmailAddressPhoneMobile:
      "È obbligatorio inserire un recapito a scelta tra: email, telefono e cellulare",
  },
  wayBillsToInvoice: "way bills to invoice",
  LoadingUserFailure: "Error loading user",
} as const;
